import React, { Component } from 'react';
import api from '../api';
import moment from 'moment';

export default class TransactionRow extends Component {
  constructor (props) {
    super(props);
    this.state = {
      loading: false,
      transaction: props.transaction
    };
  }

  componentWillReceiveProps (props) {
    this.setState({ transaction: props.transaction });
  }

  async updateCategory (categoryId, transactionId) {
    try {
      this.setState({ loading: true });
      await api.updateTransactionCategory(transactionId, categoryId);
      const transaction = { ...this.state.transaction, category_id: categoryId };
      this.setState({ loading: false, transaction });
      this.props.updateTransactionCategory(categoryId, transactionId);
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  }

  render () {
    const { accounts, categories } = this.props;
    const { transaction } = this.state;
    return <tr key={transaction.transaction_id}>
      <td>{ formatDate(transaction.date) }</td>
      <td>{ getAccountName(transaction.account_id, accounts) }</td>
      <td>{ transaction.description }</td>
      <td>
        <span className={transaction.amount > 0 ? 'tag is-primary' : ''}>{ zarString(transaction.amount) }</span>
      </td>
      <td>
        <div className={'select is-small ' + (this.state.loading ? 'is-loading' : '')}>
          <select value={transaction.category_id || ''} onChange={e => this.updateCategory(e.target.value, transaction.transaction_id)}>
            <option value=''>Uncategorized</option>
            {
              categories
                .filter(filterCategory(transaction.amount))
                .map(c => {
                  return <option key={c.category_id} value={c.category_id}>
                    {c.name}
                  </option>;
                })
            }
          </select>
        </div>
      </td>
    </tr>;
  }
}

const formatDate = (date) => {
  return moment(date).format('DD/MM/YYYY');
};

const getAccountName = (accountId, accounts) => {
  const account = accounts.find(a => a.account_id === accountId);
  return account ? account.name : 'Unknown';
};

const zarString = amount =>
  `${amount <= 0 ? '- ' : ''}R${Math.abs(parseInt(amount) / 100.0)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

const filterCategory = (amount) => (category) => {
  if (amount > 0) {
    return category.type === 'income';
  } else {
    return category.type === 'expense';
  }
};
