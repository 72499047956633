import axios from 'axios';

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  json: true
});

export default {
  async execute (method, resource, data) {
    // inject the accessToken for each request
    const accessToken = 'placeholder';
    return client({
      method,
      url: resource,
      data,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(req => {
      return req.data;
    });
  },
  getCategories () {
    return this.execute('get', '/categories');
  },
  getAccounts () {
    return this.execute('get', '/accounts');
  },
  getTransactions () {
    return this.execute('get', '/transactions');
  },
  uploadTransactions (data) {
    return this.execute('post', '/transactions', data);
  },
  updateTransactionCategory (transactionId, categoryId) {
    return this.execute('patch', `/transactions/${transactionId}`, { category_id: categoryId });
  }
};
