import React, { Component } from 'react';
import api from '../api';
import CSVToJSON from '../helpers';
/* global FileReader */

export default class UploadUtility extends Component {
  constructor (props) {
    super(props);
    this.state = {
      loading: true,
      uploadLoading: false,
      accounts: [],
      fileText: '',
      fileName: 'No file selected',
      selected: ''
    };
  }

  async componentDidMount () {
    this.setState({ loading: true });
    const accounts = await api.getAccounts();
    this.setState({ accounts, loading: false });
  }

  fileChange (files) {
    const file = files[0];
    console.log(file);
    console.log(this.state);
    const reader = new FileReader();
    reader.onload = e => {
      console.log(e.target.result);
      this.setState({ fileText: e.target.result, fileName: file.name });
    };

    reader.readAsText(file);
  }

  async handleUpload () {
    const { selected, fileText } = this.state;
    try {
      console.log(this.state.fileName, fileText);
      const data = CSVToJSON(fileText);
      this.setState({ uploadLoading: true });
      const result = await api.uploadTransactions({
        data,
        account_id: selected || null
      });
      this.setState({ uploadLoading: false });
      console.log('UPLOAD RESULT:', result);
    } catch (e) {
      this.setState({ uploadLoading: false });
      console.error(e);
    }
  }

  render () {
    const { accounts, fileName, loading, selected } = this.state;
    if (loading) {
      return <div>Loading...</div>;
    }
    return <div className='container is-fluid'>
      <h4 className='title is-4'>Upload CSV file</h4>

      <div className='columns'>
        <div className='column is-one-third'>
          <div className='select is-fullwidth'>
            <select value={selected || ''} onChange={e => this.setState({ selected: e.target.value })}>
              <option disabled value=''>Please select one</option>
              {
                accounts
                  .map(a => {
                    return <option key={a.account_id} value={a.account_id}>
                      {a.name}
                    </option>;
                  })
              }
            </select>
          </div>
          <br />
          <br />
          <div>
            <a
              className={'button is-primary is-fullwidth ' + (this.state.loading ? 'is-loading' : '')}
              onClick={() => this.handleUpload()}
            >
              Upload
            </a>
          </div>
        </div>
        <div className='column is-two-thirds'>
          <div className='file'>
            <label className='file-label'>
              <input className='file-input' type='file' name='resume' onChange={e => this.fileChange(e.target.files)} />
              <span className='file-cta'>
                <span className='file-icon'>
                  <font-awesome-icon icon='upload' />
                </span>
                <span className='file-label'>
                  Choose a file…
                </span>
              </span>
              <span className='file-name'>
                { fileName }
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>;
  }
}
