import React, { Component } from 'react';
import api from '../api';
import moment from 'moment';
import Chart from 'chart.js';

export default class Analytics extends Component {
  constructor (props) {
    super(props);
    this.state = {
      tab: '2018',
      transactions: [],
      categories: [],
      loading: true
    };
  }

  async componentDidMount () {
    this.setState({ loading: true });
    const [transactions, categories] = await Promise.all([
      api.getTransactions(),
      api.getCategories()
    ]);
    const spendingData = getSpendingData(transactions, categories);
    this.setState({ transactions, categories, spendingData, loading: false });
    this.createChart('spending-chart');
  }

  selectTab (tabName) {
    this.setState({ tab: tabName });
  }

  createChart (chartId) {
    const chartData = getChartData(this.state.spendingData);
    const ctx = document.getElementById(chartId);
    // eslint-disable-next-line
    const myChart = new Chart(ctx, {
      type: chartData.type,
      data: chartData.data,
      options: chartData.options
    });
  }

  render () {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }
    return (
      <div className='container is-fluid'>
        <div className='analytics'>
          <h4 className='title is-4'>Analytics</h4>
          <div className='tabs'>
            <ul>
              <li className={this.state.tab === '2018' ? 'is-active' : ''} >
                <a onClick={this.selectTab.bind(this, '2018')} value='2018'>2018</a>
              </li>
            </ul>
          </div>
          <h5 className='title is-5'>Chart</h5>
          <div className='chart-container'>
            <canvas id='spending-chart' />
          </div>
          <br />
          <h5 className='title is-5'>Summary</h5>
          <table className='table is-fullwidth is-bordered'>
            <thead>
              <tr>
                <th />
                { months.map((month, i) => <th key={i}>{month}</th>) }
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Income</th>
                {
                  this.state.spendingData['2018'].income.months.map((month, i) =>
                    <td key={i}>{zarString(month)}</td>
                  )
                }
                <td>{zarString(this.state.spendingData['2018'].income.total)}</td>
              </tr>
              <tr>
                <th>Expenses</th>
                {
                  this.state.spendingData['2018'].expenses.months.map((month, i) =>
                    <td key={i}>{zarString(month)}</td>
                  )
                }
                <td>{zarString(this.state.spendingData['2018'].expenses.total)}</td>
              </tr>
            </tbody>
          </table>
          <h5 className='title is-5'>Income</h5>
          <table className='table is-fullwidth is-bordered'>
            <thead>
              <tr>
                <th />
                { months.map((month, i) => <th key={i}>{month}</th>) }
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.spendingData['2018'].income.categories.map((category, i) =>
                  <tr key={category.category_id}>
                    <th>{category.name.slice(0, 10)}</th>
                    {category.months.map((month, i) => <td key={i}>{zarString(month)}</td>)}
                    <td>{zarString(category.total)}</td>
                  </tr>
                )
              }
            </tbody>
          </table>
          <h5 className='title is-5'>Expenses</h5>
          <table className='table is-fullwidth is-bordered'>
            <thead>
              <tr>
                <th />
                { months.map((month, i) => <th key={i}>{month}</th>) }
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.spendingData['2018'].expenses.categories.map((category, i) =>
                  <tr key={category.category_id}>
                    <th>{category.name.slice(0, 10)}</th>
                    {category.months.map((month, i) => <td key={i}>{zarString(month)}</td>)}
                    <td>{zarString(category.total)}</td>
                  </tr>
                )
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];
const filterOut = [
  'transfer_income',
  'transfer_expense',
  'reversal_income',
  'reversal_expense'
];

const getCategoryData = (transactions, categories) => {
  const year = 2018;
  return categories.map(c => {
    const txs = transactions.filter(t => t.category_id === c.category_id);
    const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(m => {
      const monthTxs = txs.filter(
        t => moment(t.date).year() === year && moment(t.date).month() === m
      );
      const total = monthTxs.reduce((total, t) => total + t.amount, 0);
      return total;
    });
    const total = months.reduce((total, m) => total + m, 0);

    return {
      ...c,
      months,
      total
    };
  });
};

const getIncomeData = (transactions, categories) => {
  const incomeCategories = categories.filter(
    c => c.type === 'income' && !filterOut.includes(c.key)
  );
  const categorySpending = getCategoryData(transactions, incomeCategories);
  const months = categorySpending.reduce((result, c) => {
    return c.months.map((m, i) => (result[i] || 0) + m);
  }, []);
  const total = months.reduce((total, m) => total + m, 0);
  return { categories: categorySpending, months, total };
};

const getExpenseData = (transactions, categories) => {
  const expenseCategories = categories.filter(
    c => c.type === 'expense' && !filterOut.includes(c.key)
  );
  const categorySpending = getCategoryData(transactions, expenseCategories);
  const months = categorySpending.reduce((result, c) => {
    return c.months.map((m, i) => (result[i] || 0) + m);
  }, []);
  const total = months.reduce((total, m) => total + m, 0);
  return { categories: categorySpending, months, total };
};

// returns: { "2018": { income: { categories: [], months: [], total: [] }, expenses: {} }}
const getSpendingData = (transactions, categories) => {
  const income = getIncomeData(transactions, categories);
  const expenses = getExpenseData(transactions, categories);

  // console.log(JSON.stringify({ "2018": { income, expenses } }, null, 2));

  return { '2018': { income, expenses } };
};

const zarString = amount =>
  `${amount <= 0 ? '- ' : ''}R${Math.abs(parseInt(amount) / 100.0)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

const getChartData = (spendingData) => {
  const startingBalance = 8000000;
  const data = spendingData;
  const income = data['2018'].income.months;
  const expenses = data['2018'].expenses.months.map(e => Math.abs(e));
  const diffs = income.map((m, i) => m - expenses[i]);
  const balance = diffs
    .reduce((acc, curr) => [...acc, curr + acc[acc.length - 1]], [
      startingBalance
    ])
    .splice(1);
  return {
    type: 'line',
    data: {
      labels: months,
      datasets: [
        {
          label: 'Income',
          data: income,
          borderColor: ['#36495d'],
          fill: false
        },
        {
          label: 'Expenses',
          data: expenses,
          borderColor: ['#47b784'],
          fill: false
        },
        {
          label: 'Balance',
          data: balance,
          // borderColor: ["#47b784"],
          fill: false
        }
      ]
    },
    options: {
      maintainAspectRatio: false,
      legend: {
        labels: {
          useLineStyle: true
        }
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              callback: function (value, index, values) {
                return zarString(value);
              }
            }
          }
        ]
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            return zarString(tooltipItem.yLabel);
          }
        }
      }
    }
  };
};
