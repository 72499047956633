import React, { Component } from 'react';
import { NavLink, Switch, Route, Redirect } from 'react-router-dom';
import Transactions from './transactions/transactions';
import Analytics from './analytics/analytics';
import Upload from './utilities/upload';

export default class Menu extends Component {
  constructor (props) {
    super(props);
    this.state = {
      open: false
    };
  }

  toggleMenu () {
    this.setState({ open: !this.state.open });
  }

  render () {
    return (
      <div>
        <nav className='navbar is-link' role='navigation' aria-label='main navigation'>
          <div className='navbar-brand'>
            <a
              role='button'
              className={'navbar-burger burger' + (this.state.open ? ' is-active' : '')}
              aria-label='menu'
              aria-expanded='false'
              onClick={this.toggleMenu.bind(this)}
            >
              <span aria-hidden='true' />
              <span aria-hidden='true' />
              <span aria-hidden='true' />
            </a>
          </div>
          <div className={'navbar-menu' + (this.state.open ? ' is-active' : '')}>
            <div className='navbar-start'>
              <NavLink
                className='navbar-item'
                activeClassName='is-active'
                to='/transactions'
                onClick={this.toggleMenu.bind(this)}>
                Transactions
              </NavLink>
              <NavLink
                className='navbar-item'
                activeClassName='is-active'
                to='/analytics'
                onClick={this.toggleMenu.bind(this)}>
                Anayltics
              </NavLink>

              <div className='navbar-item has-dropdown is-hoverable'>
                <a className='navbar-link'>
                  Utilities
                </a>

                <div className='navbar-dropdown'>
                  <NavLink className='navbar-item' activeClassName='is-active' to='/uploads'>Uploads</NavLink>
                </div>
              </div>
            </div>
          </div>
        </nav>

        <section className='section'>
          <div className='container is-fullhd'>
            <Switch>
              <Route path='/transactions' component={Transactions} />
              <Route path='/analytics' component={Analytics} />
              <Route path='/uploads' component={Upload} />
              <Redirect from='/' to='/transactions' />
            </Switch>
          </div>
        </section>
      </div>
    );
  }
}
