import React, { Component } from 'react';
import api from '../api';
import moment from 'moment';
import TransactionRow from './transaction-row';

export default class Trasactions extends Component {
  constructor (props) {
    super(props);
    this.state = {
      transactions: [],
      categories: [],
      accounts: [],
      loading: false,
      tab: 'all',
      page: 1,
      pageSize: 20,
      totalPages: null
    };
  }

  async componentDidMount () {
    this.setState({ loading: true });
    let [transactions, categories, accounts] = await Promise.all([
      api.getTransactions(),
      api.getCategories(),
      api.getAccounts()
    ]);
    const maxPages = Math.ceil(transactions.length / this.state.pageSize);
    this.setState({ maxPages, transactions: transactions.sort(sortTransactions), categories, accounts, loading: false });
  }

  setTab (tab) {
    this.setState({ tab, page: 1 });
  }

  updateTransactionCategory (categoryId, transactionId) {
    const transactions = this.state.transactions.map(t => {
      if (t.transaction_id === transactionId) {
        return { ...t, category_id: categoryId };
      }
      return t;
    });
    this.setState({ transactions });
  }

  updatePage (page) {
    if (page >= 1 && page <= this.state.maxPages) {
      this.setState({ page });
    }
  }

  renderPagination () {
    const currentPage = this.state.page;
    const value1 = currentPage === 1 ? 1 : currentPage - 1;
    const value2 = currentPage === 1 ? 2 : currentPage;
    const value3 = currentPage === 1 ? 3 : currentPage + 1;
    return <nav className='pagination' role='navigation' aria-label='pagination'>
      <ul className='pagination-list'>
        <li>
          <a className='pagination-link' onClick={e => this.updatePage(1)}>First</a>
        </li>
        <li>
          <a className='pagination-link' onClick={e => this.updatePage(currentPage - 1)}>{'<'}</a>
        </li>
        <li>
          <a
            className={'pagination-link' + (currentPage === value1 ? ' is-current' : '')}
            onClick={e => this.updatePage(value1)}
          >
            {value1}
          </a>
        </li>
        <li>
          <a
            className={'pagination-link' + (currentPage === value2 ? ' is-current' : '')}
            onClick={e => this.updatePage(value2)}
          >
            {value2}
          </a>
        </li>
        {
          this.state.maxPages > (currentPage === 1 ? 2 : currentPage) &&
          <li>
            <a
              className={'pagination-link' + (currentPage === value3 ? ' is-current' : '')}
              onClick={e => this.updatePage(value3)}
            >
              {value3}
            </a>
          </li>
        }
        <li>
          <a className='pagination-link' onClick={e => this.updatePage(currentPage + 1)}>{'>'}</a>
        </li>
        <li>
          <a className='pagination-link' onClick={e => this.updatePage(this.state.maxPages)}>Last</a>
        </li>
      </ul>
    </nav>;
  }

  render () {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }
    return (
      <div className='transactions'>
        <div>
          <div className='tabs'>
            <ul>
              <li className={this.state.tab === 'all' ? 'is-active' : ''}>
                <a onClick={this.setTab.bind(this, 'all')}>All</a>
              </li>
              <li className={this.state.tab === 'uncategorized' ? 'is-active' : ''}>
                <a onClick={this.setTab.bind(this, 'uncategorized')}>Uncategorized</a>
              </li>
            </ul>
          </div>
          <table className='table is-fullwidth'>
            <thead>
              <tr>
                <th>Date</th>
                <th>Account</th>
                <th>Description</th>
                <th>Amount</th>
                <th>Category</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.loading &&
                [0, 1, 2, 3, 4].map(i => LoadingTransaction(i))
              }
              {
                this.state.transactions
                  .filter(t => filterTransaction(t, this.state.tab))
                  .slice((this.state.page - 1) * this.state.pageSize, (this.state.page) * this.state.pageSize)
                  .map(t =>
                    <TransactionRow
                      transaction={t}
                      categories={this.state.categories}
                      accounts={this.state.accounts}
                      updateTransactionCategory={this.updateTransactionCategory.bind(this)}
                      key={t.transaction_id}
                    />
                  )
              }
              {
                !this.state.loading &&
                this.state.transactions.filter(t => filterTransaction(t, this.state.tab)).length === 0 &&
                <tr>
                  <td colSpan='5' className='empty'>No transactions</td>
                </tr>
              }
            </tbody>
          </table>
          {this.renderPagination()}
        </div>
      </div>
    );
  }
}

const LoadingTransaction = i => (
  <tr key={i}>
    <td> <span className='tag is-small full'>&nbsp;</span> </td>
    <td> <span className='tag is-small full'>&nbsp;</span> </td>
    <td> <span className='tag is-small full'>&nbsp;</span> </td>
    <td> <span className='tag is-small full'>&nbsp;</span> </td>
    <td> <span className='tag is-small full'>&nbsp;</span> </td>
  </tr>
);

const sortTransactions = (a, b) => {
  return moment(a.date).isBefore(moment(b.date)) ? 1 : -1;
};

const filterTransaction = (transaction, tab) => {
  switch (tab) {
    case 'uncategorized':
      return !transaction.category_id;
    default:
      return true;
  }
};
